<template>
    <div
        :id="title"
        class="bg-grey w-full px-120px py-56px flex gap-24px ipad:(pt-48px pb-24px px-24px gap-24px flex-col items-center) mobile:(px-16px pt-32px pb-0 min-h-auto gap-18px flex-col)"
    >
        <div
            class="w-384px flex flex-col justify-between ipad:(w-full items-center justify-center) mobile:(items-center w-full)"
        >
            <div class="section_headline mb-40px mobile:(leading-48px text-base-40px text-center)">
                <span class="ligne">{{ title }}</span>
            </div>
            <img
                class="justify-self-end w-full ipad:hidden mobile:hidden"
                src="../../../img/illustration_L.svg"
            />
            <img
                class="hidden justify-self-end w-full ipad:(block w-507px) mobile:(w-full)"
                src="../../../img/illustration_M.svg"
            />
            <img
                class="hidden justify-self-end w-full mobile:(block w-full)"
                src="../../../img/illustration_S.svg"
            />
        </div>
        <div class="w-792px ipad:(w-full) mobile:w-full">
            <div class="mt-24px mobile:mt-0">
                <div
                    v-for="(item, i) in allServices"
                    :key="i"
                    class="list__service rounded-16px p-40px bg-light my-24px border-solid mobile:(p-16px)"
                >
                    <div
                        :class="{ arrow: item.expand }"
                        class="flex hover:(cursor-pointer) justify-between items-center"
                        @click="item.expand = !item.expand"
                    >
                        <span
                            class="title mb-0"
                            :class="{ open: item.expand, close: !item.expand }"
                        >
                            {{ item.title }}
                        </span>
                        <img class="icon w-36px h-18px" src="../../../img/chever_down.svg" />
                    </div>
                    <collapse
                        :when="item.expand"
                        class="desc collapse text-base-16px leading-19px font-400 tracking-0.3px"
                    >
                        <p class="pt-16px">{{ item.content1 }}</p>
                        <p v-if="item.content2" class="pt-30px">{{ item.content2 }}</p>
                    </collapse>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'services',
    props: {
        services: {
            type: Array,
            default: () => []
        },
        title: String
    },
    mounted() {
        this.addExpand()
    },
    data() {
        return {
            allServices: []
        }
    },
    methods: {
        addExpand() {
            this.services.forEach((item) => {
                this.allServices.push({ ...item, expand: false })
            })
        },
        expand() {}
    }
}
</script>
<style scoped>
.open:after {
    border-radius: 5px;
    margin-top: 24px;
    content: '';
    display: block;
    width: 120px;
    height: 3px;
    background: var(--primary);
    position: relative;
}
.close:after {
    border-radius: 5px;
    margin-top: 24px;
    content: '';
    display: block;
    width: 32px;
    height: 3px;
    background: var(--primary);
    position: relative;
}
.collapse {
    transition: height 400ms cubic-bezier(0.3, 0, 0.6, 1);
}

.icon {
    transition: transform 400ms ease-out;
}
.arrow .icon {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
</style>
